import React, { useEffect, useState } from "react";
import watch from "../Assets/angled-watch.png";
import blob from "../Assets/blob-haikei.svg";
import wave from "../Assets/wave.svg";
import christmus from "../Assets/Christmass-DOOHmp4.mp4";
import colgate from "../Assets/Colgare-Retail.mp4";
import jwelry from "../Assets/Jewelry-DOOH.mp4";
import decor from "../Assets/decor.mp4";
import "aos/dist/aos.css";
import "./Header.css";
import Aos from "aos";
import { FaPlayCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";
const Header = () => {
  // var currentVidIndex = 0;
  // const [activebox, setActiveBox] = useState([" active", "", ""]);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(2); // Start with the last video index (assuming it's initially active)
  const videoSources = [jwelry, colgate, decor, christmus];
  const activebox = ["inactive", "inactive", "inactive", "inactive"];
  activebox[currentVideoIndex] = "active";

  // Function to be called when a video ends
  const handleEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
  };

  useEffect(() => {
    Aos.init();
    const videoElements = document.querySelectorAll(".bg-video");
    const currentVideoElement = videoElements[currentVideoIndex];
    if (currentVideoElement) {
      currentVideoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <div
      id="header"
      className="bg-white flex justify-center relative  py-10 pt-16 lg:pt-28 h-screen  overflow-hidden"
    >
      <Helmet>
        <title>Holoscreen.lk</title>
        <meta
          name="description"
          content="Sri Lanka's No 1 Holographic Solution"
        />
        <meta property="og:title" content="Holoscreen.lk Home" />
        <meta
          property="og:description"
          content="Sri Lanka's No 1 Holographic Solution"
        />
      </Helmet>
      <div className="video-container absolute top-0 left-0 w-full h-full overflow-hidden z-0">
        {videoSources.map((src, index) => (
          <video
            key={index}
            className={`bg-video ${activebox[index]}`}
            onEnded={handleEnd}
            src={src}
            muted
            autoPlay={index === currentVideoIndex}
          ></video>
        ))}
      </div>
      <div className="overlay absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>
      <div className="lg:container flex flex-col-reverse  items-center justify-center md:justify-between z-20">
        <div
          className="flex flex-col "
          data-aos="fade-right"
          data-aos-easing="ease-in-out"
          data-aos-delay="200"
          data-aos-duration="500"
          data-aos-once="false"
        >
          {/* <div className="flex-1 "></div> */}
          <h1 className="text-6xl md:w-[80vw] pt-20 md:pt-8 px-2 md:px-10 md:text-8xl text-center  font-sans font-extrabold flex-1 text-white">
            Experience the Future with Holoscreens
          </h1>
          <div className="mt-8 flex flex-col md:flex-row pb-24 items-center justify-center gap-4">
            <a
              className="inline-flex rounded h-14 w-44 bg-white text-black px-7 py-3 text-sm font-bold items-center justify-center"
              href="tel:+94762248365"
            >
              Call Now
            </a>
            <a href="#order">
              <a
                className="inline-flex rounded h-14 w-44 bg-transparent backdrop-blur border-2 border-blue-10 py-3 text-sm text-white font-bold items-center justify-center"
                href="#demo"
              >
                Watch Demo
                <FaPlayCircle color="white" className="mt-1 ml-1" />
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
