import React from "react";
import Holoscreenlogo from "../Assets/holoscreen-logo-horizontal.png";
import { Icon } from "@iconify/react";

const Footer = () => {
  return (
    <footer id="footer" className="footer p-10 bg-[#00d374] text-white test">
      <div>
        <img src={Holoscreenlogo} className="h-14" alt="" />
      </div>
      <div>
        <span className="text-3xl font-bold">Information</span>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 pr-2 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>
          </span>
          165, Temple road, Ampitiya, Kandy
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 pr-2 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
          </span>
          info@holoscreen.lk
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <span class="me-3 pr-2 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <a href="tel:+94762248365"> (+94)76 2248 365</a>
        </p>
      </div>
      <div>
        <span className="text-3xl font-bold">Support</span>
        <a href="tel:+94762248365" className="link link-hover">
          +94762248365
        </a>
      </div>
      <div>
        <span className="text-3xl font-bold">Follow Us</span>
        <div className="flex gap-5">
          <a
            href="https://www.facebook.com/profile.php?id=100067099699287"
            className="link link-hover text-4xl"
          >
            <Icon icon="ic:baseline-facebook" />
          </a>
          <a
            href="https://www.instagram.com/holoscreen_lk/"
            className="link link-hover text-4xl"
          >
            <Icon icon="mdi:instagram" />
          </a>
          <a className="link link-hover text-4xl">
            <Icon icon="entypo-social:twitter-with-circle" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
