import { Icon } from "@iconify/react";
import React from "react";
import image from "../Assets/faq-blob1.png";

const Faq = () => {
  return (
    <div id="faq" className="flex justify-center">
      <div className="pt-20">
        <div className="">
          <h1
            className="text-6xl text-center font-semibold"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            FAQ
          </h1>
          <p
            className="mx-auto mt-3 text-2xl font-semibold text-gray-400 max-w-2xl text-center"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            Some frequently asked questions.
          </p>
        </div>
        <div className="md:container flex flex-col-reverse md:flex-row gap-y-20 gap-10 items-center my-32 md:my-40">
          {/* grid grid-cols-2 items-start justify-between */}
          <div className="flex flex-col gap-3 md:w-6/12">
            <div
              tabIndex="0"
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                What are some creative uses for the Holoscreen Display
              </div>
              <div className="collapse-content bg-white">
                <p>
                  Our customers use them for advertising, events, trade shows,
                  retail displays, and even home decoration to create
                  eye-catching visual effects.
                </p>
              </div>
            </div>
            <div
              tabIndex="0"
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                Is the Holoscreen Diplays water Resistant?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  The Holoscreen Display is not water-resistant by default.
                  However, you can make it water-resistant by covering the
                  display with a protective shell for outdoor use.
                </p>
              </div>
            </div>

            <div
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                Can I claim warrenty if there is an issue?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  You can claim warrenty within 1 year after purchasing the
                  product. To claim warrenty you have to bring the product with
                  warrenty certificate. We will observe the problem and repair
                  it. If it's unrepairable then we will replce the product and
                  provide a new one.
                </p>
              </div>
            </div>
            <div
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                How does a Holographic Fan Display work?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  It operates by spinning LED light bars at high speeds,
                  creating the illusion of a 3D hologram through persistence of
                  vision. The result is a mesmerizing floating image or
                  animation.
                </p>
              </div>
            </div>
            <div
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                How do I install the Holographic Fan Display?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  The display comes with a detailed installation guide.
                  Generally, it involves mounting the fan on a stable surface or
                  wall and connecting it to a power source.
                </p>
              </div>
            </div>
            <div
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                Can I customize the content on the Holoscreen Display?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  Absolutely! You can easily upload and display your own images,
                  videos, and animations using the provided software or mobile
                  app.
                </p>
              </div>
            </div>
            <div
              className="collapse collapse-plus"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title flex items-center gap-2 bg-green-400 rounded text-white font-bold text-xl">
                <Icon icon="mdi:question-mark-circle" className="text-2xl" />
                What are the key features of the Holographic Fan Display?
              </div>
              <div className="collapse-content bg-white">
                <p>
                  Some key features include high-resolution display, 3D
                  visualization, remote control operation, compatibility with
                  various media formats, and easy installation.
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
            className="md:w-6/12"
          >
            <figure className="flex justify-end relative">
              <img src={image} alt="Trulli" className="relative z-10" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
