import React, { useState } from "react";
import iornman from "../Assets/iorn_man.mp4";
import connect from "../Assets/analytics.png";
import confiqure from "../Assets/management.png";
import trophy from "../Assets/trophy.png";
import Review from "./Review";
import watches from "../Assets/multiple-watches2.png";

import { FcIdea } from "react-icons/fc";

import security from "../Assets/insurance.png";
import idea from "../Assets/light-bulb.png";
import bills from "../Assets/invoice.png";
import tech from "../Assets/technology.png";
import heart from "../Assets/love.png";

import key from "../Assets/key.png";
import voice from "../Assets/voice-record.png";
import apps from "../Assets/copy.png";
import like from "../Assets/like.png";
import person from "../Assets/user.png";
import watch from "../Assets/smart-watch.png";

import swal from "sweetalert";
import Faq from "./Faq";
import "./Center.css";
const Center = () => {
  const [amount, setAmount] = useState(0);
  const ProgressBar = ({ progress }) => {
    return (
      <div className="bg-gray-200 rounded-full h-4 w-full">
        <div
          className="bg-green-400 rounded-full h-4"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };

  const placeOrder = (event) => {
    event.preventDefault();

    const data = {
      name: event.target.name.value,
      phone: event.target.phone.value,
      email: event.target.email.value,
      address: event.target.address.value,
      city: event.target.city.value,
      area: event.target.area.value,
      qty: parseInt(event.target.qty.value),
    };
    if (
      data.name &&
      data.phone &&
      data.email &&
      data.address &&
      data.city &&
      data.area &&
      data.qty
    ) {
      const error = document.getElementById("nameError");
      if (error) {
        error.remove();
      }
      const error1 = document.getElementById("phoneError");
      if (error1) {
        error1.remove();
      }
      const error2 = document.getElementById("emailError");
      if (error2) {
        error2.remove();
      }
      const error3 = document.getElementById("addressError");
      if (error3) {
        error3.remove();
      }
      const error4 = document.getElementById("cityError");
      if (error4) {
        error4.remove();
      }
      const error5 = document.getElementById("areaError");
      if (error5) {
        error5.remove();
      }
      const error6 = document.getElementById("qtyError");
      if (error6) {
        error6.remove();
      }
      /* const error = document.getElementById('nameError')
                if(error){
                    error.remove()
                } */
      fetch("http://localhost:5000/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Success:", data);
          if (data.acknowledged) {
            swal({
              title: "Order Placed Successfully!",
              text: "Your order has been placed. Our agent will call you regarding the order. If you have any query please contact us at : +880132***785",
              icon: "success",
              button: "OK",
            });
            event.target.reset();
          }
        })
        .catch((error) => {
          // console.error("Error:", error);
          if (error) {
            swal({
              title: "Something went wrong!",
              text: "Please try again later or if this happen multiple times then please contact us at : +880132***785",
              icon: "error",
              button: "OK",
            });
          }
        });
    } else {
      if (!data.name) {
        const input = event.target.name;
        const error = document.getElementById("nameError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='nameError' class='text-red-500 ml-2 font-semibold text-sm'>Full Name is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='nameError' class='text-red-500 ml-2 font-semibold text-sm'>Full Name is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("nameError");
        if (error) {
          error.remove();
        }
      }
      // Name validation end
      if (!data.phone) {
        const input = event.target.phone;
        const error = document.getElementById("phoneError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='phoneError' class='text-red-500 ml-2 font-semibold text-sm'>Phone Number is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='phoneError' class='text-red-500 ml-2 font-semibold text-sm'>Phone Number is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("phoneError");
        if (error) {
          error.remove();
        }
      }
      // Phone validation end
      if (!data.email) {
        const input = event.target.email;
        const error = document.getElementById("emailError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='emailError' class='text-red-500 ml-2 font-semibold text-sm'>Email is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='emailError' class='text-red-500 ml-2 font-semibold text-sm'>Email is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("emailError");
        if (error) {
          error.remove();
        }
      }
      // Email validation end
      if (!data.address) {
        const input = event.target.address;
        const error = document.getElementById("addressError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='addressError' class='text-red-500 ml-2 font-semibold text-sm'>Address is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='addressError' class='text-red-500 ml-2 font-semibold text-sm'>Address is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("addressError");
        if (error) {
          error.remove();
        }
      }
      // Address validation end
      if (!data.city) {
        const input = event.target.city;
        const error = document.getElementById("cityError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='cityError' class='text-red-500 ml-2 font-semibold text-sm'>City is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='cityError' class='text-red-500 ml-2 font-semibold text-sm'>City is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("cityError");
        if (error) {
          error.remove();
        }
      }
      // City validation end
      if (!data.area) {
        const input = event.target.area;
        const error = document.getElementById("areaError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='areaError' class='text-red-500 ml-2 font-semibold text-sm'>Area is Required!<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='areaError' class='text-red-500 ml-2 font-semibold text-sm'>Area is Required!<span/>"
          );
        }
      } else {
        const error = document.getElementById("areaError");
        if (error) {
          error.remove();
        }
      }
      // Area validation end
      if (data.qty === 0 || !data.qty) {
        const input = event.target.qty;
        const error = document.getElementById("qtyError");
        if (error) {
          error.remove();
          input.insertAdjacentHTML(
            "afterend",
            "<span id='qtyError' class='text-red-500 ml-1 font-semibold text-sm'>Minimum order quantity is 1<span/>"
          );
        } else {
          input.insertAdjacentHTML(
            "afterend",
            "<span id='qtyError' class='text-red-500 ml-1 font-semibold text-sm'>Minimum order quantity is 1<span/>"
          );
        }
      } else {
        const error = document.getElementById("qtyError");
        if (error) {
          error.remove();
        }
      }
      // Qty validation end
    }
  };
  return (
    <div className="mt-[-40px] pt-14">
      <section className="flex justify-center pb-20 bg-base-200">
        <div className="container flex flex-col md:grid md:grid-cols-3 gap-y-10 md:gap-y-0 justify-center gap-5">
          <div
            className="card w-auto bg-white shadow-xl text-center py-5"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            <figure>
              <img
                src={connect}
                alt="Connect"
                className="w-12 md:w-16 lg:w-24"
              />
            </figure>
            <div className="card-body md:p-2 lg:p-5 xl:p-8">
              <h2 className="text-2xl font-semibold">Connect Device</h2>
              <p>
                Connect the device with our app to track all activities and
                update display outputs, adjust brightness.
              </p>
            </div>
          </div>
          <div
            className="card w-auto bg-white shadow-xl text-center py-5"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            <figure>
              <img
                src={confiqure}
                alt="confiqure"
                className="w-12 md:w-16 lg:w-24"
              />
            </figure>
            <div className="card-body md:p-2 lg:p-5 xl:p-8">
              <h2 className="text-2xl font-semibold">Configure it</h2>
              <p>
                Install our app from appstore and configure it with your
                Holoscreen model. Once it is configured you will get upload all
                desired videos and images of yours.
              </p>
            </div>
          </div>
          <div
            className="card w-auto bg-white shadow-xl text-center py-5"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            <figure>
              <img src={trophy} alt="trophy" className="w-12 md:w-16 lg:w-24" />
            </figure>
            <div className="card-body md:p-2 lg:p-5 xl:p-8">
              <h2 className="text-2xl font-semibold">Yay! Done</h2>
              <p>
                After configuring the holsocreen you are all set for marketing.
                You can now grab all the attention over there.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex justify-center bg-base-200 pt-5">
        <div className="grid lg:grid-cols-3 items-center container">
          <div className="flex flex-col gap-10">
            <div
              className="flex gap-3 justify-start border-r-2 border-b-2 p-5 border-gray-200"
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-duration="400"
              data-aos-easing="ease-in-sine"
            >
              <div className="p-4">
                <h1 className="text-3xl text-green-400 font-extrabold">80%</h1>
                <ProgressBar progress={80} />
                <p className="text-2xl font-extrabold mt-2">
                  Boost emotional response
                </p>
                <p className="text-1xl font-light">
                  {80}% percent of people showed signs of happiness interacting
                  with Holoscreen.
                </p>
              </div>
            </div>
            <div
              className="flex gap-3 justify-start border-r-2 border-b-2 p-5 border-gray-200"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="400"
              data-aos-easing="ease-in-sine"
            >
              <div className="p-4">
                <h1 className="text-3xl text-green-400 font-extrabold">2X</h1>
                <ProgressBar progress={100} />
                <p className="text-2xl font-extrabold mt-2">Lead Generation</p>
                <p className="text-1xl font-light">
                  2x higher chance of a holographic image being seen.
                </p>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center items-center order-3 lg:order-none mt-32 lg:mt-0"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="400"
            data-aos-easing="ease-in-sine"
          >
            <div className="flex justify-center rounded-lg items-center  p-2 bg-black w-72">
              <video
                id="demo"
                className="rounded-lg"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                autoPlay
                loop
                muted
              >
                <source src={iornman} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="flex flex-col gap-10">
            <div
              className="flex gap-3 justify-start border-l-2 border-b-2 p-5 border-gray-200"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="400"
              data-aos-easing="ease-in-sine"
            >
              <div className="p-4">
                <h1 className="text-3xl text-green-400 font-extrabold">40%</h1>
                <ProgressBar progress={40} />
                <p className="text-2xl font-extrabold mt-2">
                  Drive Brand Awareness
                </p>
                <p className="text-1xl font-light">
                  40% longer viewing and engagement time compared with standard
                  2D digital signage.
                </p>
              </div>
            </div>
            <div
              className="flex gap-3 justify-start border-l-2 border-b-2 p-5 border-gray-200"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="400"
              data-aos-easing="ease-in-sine"
            >
              <div className="p-4">
                <h1 className="text-3xl text-green-400 font-extrabold">40%</h1>
                <ProgressBar progress={40} />
                <p className="text-2xl font-extrabold mt-2">Increase Sales</p>
                <p className="text-1xl font-light">
                  Holoscreen solutions generate up to 40% increase of promoted
                  product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex justify-center bg-base-200 pt-10 py-8">
        <div className="container">
          <h1
            className="text-center text-6xl font-semibold"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            The Benefits
          </h1>

          <p
            className="text-center text-2xl text-gray-500 lg:w-6/12 mx-auto py-4"
            data-aos="zoom-in-up"
            data-aos-delay="400"
            data-aos-duration="400"
            data-aos-easing="ease-in-out"
          >
            HoloScreens make your daily life easier and more productive. With
            these displays, you can achieve virtually anything and everything.
          </p>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 pt-20 pb-5 gap-10">
            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <span className="w-12 h-12">
                <FcIdea size="60" />
              </span>

              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Drive traffic into stores
                </h1>
                <p className="font-sans">
                  Make a strong first impression and convert passers-by into
                  your visitors with holoscreen solutions
                </p>
              </div>
            </div>
            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <img src={idea} alt="" className="w-12 h-12" />
              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Innovative Visualization
                </h1>
                <p className="font-sans">
                  {" "}
                  With our holographic displays, we introduce innovative ways to
                  visualize content in three dimensions. This enhances
                  creativity and problem-solving.
                </p>
              </div>
            </div>
            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <img src={security} alt="" className="w-12 h-12" />
              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Increase brand awareness
                </h1>
                <p className="font-sans">
                  Strengthen your brand, set it apart in the industry, and
                  increase its perceived value
                </p>
              </div>
            </div>

            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <img src={bills} alt="" className="w-12 h-12" />
              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Save your bills
                </h1>
                <p className="font-sans">
                  Holographic displays reduce the need for physical prototypes
                  and models. Businesses can save on production costs and
                  resources.
                </p>
              </div>
            </div>
            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <img src={tech} alt="" className="w-12 h-12" />
              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Generate revenue
                </h1>
                <p className="font-sans">
                  Draw attention to your offer and trigger optional sales of
                  high-margin products and services.{" "}
                </p>
              </div>
            </div>
            <div
              className="flex justify-start gap-3"
              data-aos="zoom-in-up"
              data-aos-delay="300"
              data-aos-duration="600"
              data-aos-easing="ease-in-sine"
            >
              <img src={heart} alt="" className="w-12 h-12" />
              <div>
                <h1 className="pt-0 mt-0 text-xl font-semibold">
                  Increase customer loyalty
                </h1>
                <p className="font-sans">
                  Deliver a next-level in-store visual experience to build
                  relationships with your customers and make them want to
                  return.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product description SECTION */}
      {/* <section id="description" className="flex justify-center">
        <div className="container grid md:grid-cols-2 items-center justify-items-center md:justify-items-start gap-y-32 md:gap-y-0 py-40">
          <div
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            <img src={watches} alt="watches" />
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            <h1 className="text-4xl font-semibold">Product Description</h1>
            <p className="py-5">
              Introducing our latest product, the Smart Watch, the ultimate
              device for keeping you connected and on top of your daily routine.
              This sleek and stylish device is the perfect combination of
              functionality and fashion, making it a must-have accessory for
              anyone looking to enhance their daily life. With its intuitive
              touch screen display, you can easily navigate through all of your
              important information such as emails, messages, calls, and social
              media updates, all with a simple swipe of your finger. Plus, with
              built-in fitness tracking features, the Smart Watch allows you to
              monitor your activity levels, track your steps, and even monitor
              your heart rate, all in real-time.
              <br /> <br />
              Designed to seamlessly integrate with your smartphone, the Smart
              Watch provides you with instant access to all of your important
              notifications, no matter where you are. Whether you're in a
              meeting, at the gym, or simply running errands, you'll never miss
              a beat with the Smart Watch. The Smart Watch is also incredibly
              durable and long-lasting, featuring a water-resistant design and a
              battery life that lasts for days on a single charge. And with its
              customizable watch face and interchangeable bands, you can easily
              personalize your Smart Watch to fit your unique style. Overall,
              the Smart Watch is the ultimate device for anyone looking to stay
              connected, stay active, and stay stylish, all in one compact and
              convenient package. Whether you're a busy professional, a fitness
              enthusiast, or just looking for a convenient way to stay on top of
              your daily routine, the Smart Watch has everything you need to
              stay ahead of the game.
            </p>
            <a href="#order">
              <button type="" className="btn btn-success mt-5 text-white">
                Order Now
              </button>
            </a>
          </div>
        </div>
      </section> */}

      {/* <section className="flex justify-center bg-white py-5">
        <div className="container">
          <h1
            className="text-center text-6xl font-semibold"
            data-aos="zoom-in"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-easing="ease-in-sine"
          >
            TECH SPECS
          </h1>
          <p
            className="text-center text-2xl text-gray-500 w-6/12 mx-auto py-4"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
          >
            We have implementend much more functionalities which you will
            definitely love.
          </p>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 pt-20 pb-24 lg:gap-x-20 gap-y-10">
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-right"
              data-aos-delay="1400"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Compatibility</h1>
              <p className="font-sans text-gray-500">
                iPhone iOS 7 & up Android 4.3 & uptexts Bluetooth 4.0
                Notification for calls / texts
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="1500"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">App</h1>
              <p className="font-sans text-gray-500">
                Set daily goals Analyze history Sync to phone & cloud Connect to
                Apple health kit Connect to Google fit Watch firmware upgrade
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="1600"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">
                Vibration silent alarms
              </h1>
              <p className="font-sans text-gray-500">
                Phone notifications Daily goal reached Daily wake-up alarm
                Location assistance Accessiblity features
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="1700"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Watch</h1>
              <p className="font-sans text-gray-500">
                12.6mm thickness 3.16L stainless steel case 40mm diameter 5 ATM
                water resistant Sapphire glass crystal
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="1800"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Straps</h1>
              <p className="font-sans text-gray-500">
                Italian leather with natural lining Comfort sport fabric
                Quick-release pins
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="1900"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Activity tracking</h1>
              <p className="font-sans text-gray-500">
                Step counting Distance Calories burned Swimming (strokes)
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="2000"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Timekeeping</h1>
              <p className="font-sans text-gray-500">
                Swiss ETA movement Accurate, reliable
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="2100"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Battery power</h1>
              <p className="font-sans text-gray-500">
                Standard CR2025 6+ months Standard 364 for timekeeping 5+ years
                Solar Power available as aupgrade
              </p>
            </div>
            <div
              className="border-b mx-5 sm:mx-16"
              data-aos="fade-up-right"
              data-aos-delay="2200"
              data-aos-duration="500"
              data-aos-easing="ease-in-sine"
            >
              <h1 className="text-2xl font-semibold">Sensors</h1>
              <p className="font-sans text-gray-500">
                MEMS 3-axis accelerometer Highly accurate Low power consumption
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* REVIEW SECTION */}
      {/* <section id="reviews">
        <Review />
      </section> */}
      <section>
        <Faq />
      </section>
      {/* <section id="order" className="flex justify-center py-40 bg-base-200">
        <div className="lg:container flex flex-col md:flex-row justify-center gap-40">
          <div className="card image-full">
                        <figure><img src={map} alt="map" /></figure>
                        <div className="flex items-center justify-center">
                            <p className='w-96 text-black'>Lorem ipsum dolor sit amet, consectetur de elit, sed do tempor incididunt ut labore eta rehenderit in voluptate velit.</p>
                        </div>
                    </div> 
          <div className="flex flex-col gap-10 w-96">
            <div>
              <h1 className="text-2xl font-semibold">Address</h1>
              <p>27 heisenberg, new street california.</p>
            </div>
            <div>
              <h1 className="text-2xl font-semibold">Phone</h1>
              <p>+98574455745</p>
            </div>
            <div>
              <h1 className="text-2xl font-semibold">Email</h1>
              <p>contact@watchworld.com</p>
            </div>
          </div>
          <div className="lg:w-6/12">
            <form onSubmit={(e) => placeOrder(e)}>
              <div className="sm:flex sm:gap-5">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Full Name <span className="text-red-600">&#42;</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="John Doe"
                    className="input input-bordered sm:w-full bg-white"
                    name="name"
                  />
                </div>
                <div className="form-control w-full ">
                  <label className="label">
                    <span className="label-text">
                      Phone Number <span className="text-red-600">&#42;</span>
                    </span>
                  </label>
                  <input
                    type="number"
                    placeholder="+987********"
                    className="input input-bordered w-full bg-white"
                    name="phone"
                    onKeyDown={(evt) =>
                      ["e", "E", "-"].includes(evt.key) && evt.preventDefault()
                    }
                    min="0"
                  />
                </div>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    Email Address <span className="text-red-600">&#42;</span>
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="example@gmail.com"
                  className="input input-bordered w-full bg-white"
                  name="email"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    Delivery Address <span className="text-red-600">&#42;</span>
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="12/24, new street road"
                  className="input input-bordered w-full bg-white"
                  name="address"
                />
              </div>
              <div className="sm:flex sm:gap-5">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      City <span className="text-red-600">&#42;</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="California"
                    className="input input-bordered w-full bg-white"
                    name="city"
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Area <span className="text-red-600">&#42;</span>
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="New street"
                    className="input input-bordered w-full bg-white"
                    name="area"
                  />
                  
                  <label className="label">
                    <span className="label-text-alt"></span>
                    <span className="label-text-alt text-gray-400">
                      ex: Dhanmondi
                    </span>
                  </label>
                </div>
              </div>
              <div className="flex items-end justify-between">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      Select Qty <span className="text-red-600">&#42;</span>
                    </span>
                  </label>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col w-full">
                      <input
                        type="number"
                        placeholder="0"
                        className="input input-bordered w-3/12 bg-white"
                        name="qty"
                        min="0"
                        onChange={(e) => setAmount(e.target.value * 500)}
                      />
                    </div>
                    <p className="font-semibold w-full">Amount: {amount}</p>
                  </div>
                </div>
                
                <input
                  type="submit"
                  className="btn btn-success text-white"
                  value="Place Order"
                />
              </div>
            </form>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Center;
